<template>
  <div class="option-group option-group-icon option-chart-correlation" id="option-group" ref="option_group">
    <label class="toolbar-title text-primary" v-if="label">{{ label }}</label>
    <div class="option-container">
      <template>
        <label @click="option_modal_flag = !option_modal_flag" class="option-title"> <feather-icon size="18" icon="SettingsIcon" /></label>
        <div class="option" :class="{ 'show-option': option_modal_flag, 'hidden-option': !option_modal_flag }">
          <div class="option-content card mb-0">
            <slot name="content ">
              <AppCollapse class="layer-collapse" :active="true">
                <!-- <AppCollapseItem :disabled="true" :active="true" :isVisible="true" @click.native.stop v-show="methodList.length > 0" :title="$t('correlation_method')" class="layer-collapse-item" @open-collapse="openCollapse('radioContainerMethod')">
                  <template #header>
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('correlation_method')" icon="HashIcon" />
                      <p class="mb-0">{{ $t('correlation_method') }}</p>
                    </div>
                  </template>
                  <RadioGroup :moveCount="`option-correlation-method-${moveCountRadioGroup}`" :name="`option-correlation-method`" :hiddenLable="true" :label="$t('correlation_method')" :options="methodList" v-model="method" ref="radioContainerMethod" />
                </AppCollapseItem> -->
                <AppCollapseItem :disabled="true" :active="true" :isVisible="true" @click.native.stop v-show="scaleChoices.length > 0" :title="$t('auto_scale')" class="layer-collapse-item" @open-collapse="openCollapse('radioContainerScale')">
                  <template #header>
                    <div class="d-flex align-items-center select-tilte">
                      <feather-icon :title="$t('auto_scale')" icon="Minimize2Icon" />
                      <p class="mb-0">{{ $t('auto_scale') }}</p>
                    </div>
                  </template>
                  <RadioGroup :moveCount="`option-correlation-auto-scale-${moveCountRadioGroup}`" :name="`option-correlation-auto-scale`" :toggle="true" :hiddenLable="true" :label="$t('auto_scale')" :options="scaleChoices" v-model="autoScale" ref="radioContainerScale" />
                </AppCollapseItem>
              </AppCollapse>
            </slot>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
const _ = require('lodash');
import RadioGroup from './RadioGroup.vue';
import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import FillIcon from '/static/images/layer-config/prepend/fill2.svg';
import PalettePicker from './PalettePickerV2.vue';
import Option from '../tabs/boxplot/Option.vue';
import MultiRange from './MultiRange.vue';

export default {
  props: ['label', 'nameComponent'],
  components: { FeatherIcon, AppCollapse, AppCollapseItem, FillIcon, PalettePicker, RadioGroup, Option, MultiRange },
  data() {
    return {
      option_modal_flag: false,
      moveCountRadioGroup: 0,
    };
  },
  mounted() {
    this.click_outside_div(this.$refs.option_group);
  },
  computed: {
    methodList() {
      return [
        { text: this.$i18n.t('pearsonr'), value: 'pearsonr' },
        { text: this.$i18n.t('spearmanr'), value: 'spearmanr' },
        { text: this.$i18n.t('kendalltau_a'), value: 'kendalltau_a' },
        { text: this.$i18n.t('kendalltau_b'), value: 'kendalltau_b' },
      ];
    },
    scaleChoices() {
      return [
        { text: this.$i18n.t('trim'), value: 'trim' },
        { text: this.$i18n.t('zero'), value: 'zero' },
        { text: this.$i18n.t('forward'), value: 'forward' },
        { text: this.$i18n.t('backward'), value: 'backward' },
        { text: this.$i18n.t('linear'), value: 'linear' },
      ];
    },
    method: {
      get() {
        return this.$store.state.tabs[this.nameComponent].method;
      },
      set(method) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { method });
      },
    },
    autoScale: {
      get() {
        return this.$store.state.tabs[this.nameComponent].autoScale;
      },
      set(autoScale) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { autoScale });
      },
    },
  },

  watch: {
    option_modal_flag(isShow) {
      if (isShow == true && this.autoScale && this.autoScale != '') {
        this.moveCountRadioGroup++;
      }
    },
  },
  methods: {
    openCollapse(nameRefs) {
      this.$nextTick(() => {
        this.$refs[nameRefs].moveIndicator();
      });
    },
    click_outside_div(div) {
      const option_group = div;
      document.addEventListener('mousedown', (event) => {
        if (!option_group.contains(event.target)) {
          this.option_modal_flag = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.option {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 445px;
  border: solid 10px rgba(57, 123, 255, 0.12);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 5px;
  transition: all linear 0.2s;
  color: rgb(128, 128, 128);

  &-content {
    border-radius: 5px;
    padding: 10px;
    &-size {
      &-items {
        visibility: hidden;
        opacity: 0;
        transform: scaleY(0);
        height: 0;
        &.show {
          visibility: visible;
          opacity: 1;
          transform: scaleY(1);
          transition: all linear 0.2s;
          height: auto;
        }
        label {
          padding: 1px !important;
        }
      }
      &-ip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 0.5px #7b7b7b4a;
        border-radius: 5px;
        padding: 0px 6px;
        input {
          padding: 0 2px;
          max-width: 202px;
          border: none;
          color: #5e5873;
          background-color: transparent;
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .ip_text {
          flex: 1;
          max-width: 1.8rem;
        }
        .ip_range {
          margin-left: 6px;
          flex: 1;
        }
      }
      &-auto {
        margin-left: 2px !important;
        font-size: 14px;
      }
    }
    .btn-resets-size {
      button {
        padding: 8px;
        font-size: 10px;
        margin-left: 3px;
      }
    }
  }
  &-title {
    width: 100% !important;
    padding: 0 !important;
  }

  .plot-container {
    width: 100% !important;
  }

  .chart-shape-plotly {
    width: 100%;
    height: 300px;
    overflow: hidden scroll !important;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }

  #shape-selected {
    border: solid 1px #787c825c;
    border-radius: 4px;
    max-height: 245px;
    height: 36px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
  .items-no-group {
    padding: 0.45rem 0;
  }
  &-group {
    width: fit-content;
    .option-modal {
      border: none;
      background: transparent;
      .option {
        visibility: hidden;
        opacity: 0;
        top: calc(100% - 20px);
      }
      &:focus {
        border: none;
        outline: none;
        .option {
          visibility: visible;
          opacity: 1;
          top: calc(100% + 10px);
        }
      }
    }
  }
  &-container {
    border: 1px solid rgba(115, 103, 240, 0.24);
    border-radius: 0.357rem;
    padding: 4px;
    display: flex;
    position: relative;
    user-select: none;
    width: 50px;
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin: 0;
      padding: 0 12px;
      cursor: pointer;
      border-radius: 0.357rem;
      white-space: nowrap;
      width: fit-content;
    }
  }
  .layer-collapse-item {
    .select-tilte {
      p {
        line-height: 22px;
        text-transform: uppercase;
      }
      & > svg {
        margin-left: 1px;
        margin-right: 11px;
        min-width: 14px;
      }
    }
  }
  .color-title {
    svg {
      width: 14px;
      height: 14px;
      transform: rotateY(180deg);
      margin-left: 1px;
      margin-right: 11px;
      min-width: 14px;
    }
  }
  .custom-checkbox-icon-switch {
    padding-left: 1.5rem !important;
  }
  .size-width {
    &-range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.857rem;
      border: solid 0.5px #7b7b7b4a;
      border-radius: 5px;
      padding: 4px 6px;
      margin-top: 10px;
      &-label {
        width: 60px;
      }
      &-content {
        flex: 1;
        max-width: 200px;
      }
    }
  }
}
.ml-10 {
  margin-left: 10px;
}

.show-option {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1rem);
  z-index: 2;
}
.hidden-option {
  visibility: hidden;
  opacity: 0;
  top: calc(100% - 20px);
  z-index: -1;
}
</style>

<style lang="scss">
.option-type-layout svg path {
  stroke: rgb(128, 128, 128) !important;
  stroke-width: 2 !important;
  fill: rgb(128, 128, 128) !important;
}

.chart-shape-plotly {
  g {
    cursor: pointer !important;
  }

  .plot-container .main-svg {
    top: -25px !important;
  }
}

.chart-shape-selected {
  g {
    cursor: pointer !important;
  }

  .plot-container .main-svg {
    top: 5px !important;
  }
}
.option {
  &-content {
    .card-header {
      padding: 0.45rem 0 !important;
    }
    .card-body {
      padding: 0 0 0.5rem 0 !important;
    }
  }
  .multi-range {
    display: flex;
    justify-content: flex-end;
  }
}
.shape-seleted-label {
  padding-left: 0 !important;
}
.dark-layout {
  .option-modal {
    color: rgb(128, 128, 128);
  }
  .option-content-size {
    input {
      color: rgb(128, 128, 128);
    }
  }
}
.correlation-card {
  .layer-collapse-item {
    .card-header::after {
      display: none;
    }
  }
}
</style>
