<template>
  <b-card no-body class="correlation-chart">
    <b-card-body>
      <img :src="chart" alt="" v-if="chart" />
      <EmptyChart :description="$t('tips_correlation')" v-else />
    </b-card-body>
  </b-card>
</template>

<script>
import EmptyChart from '../../common/EmptyChart.vue'

export default {
  props: ['chart'],
  components: { EmptyChart },
}
</script>

<style>
.correlation-chart .card-body {
  padding-top: 0;
}
.correlation-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 0px - 40px);
}
.correlation-chart img {
  width: 100%;
  height: 100%;
}
</style>
