<template>
  <button class="btn-pushable-container" :class="'btn-pushable-' + variant" role="button">
    <span class="btn-pushable-shadow"></span>
    <span class="btn-pushable-edge"></span>
    <span class="btn-pushable-front">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: () => { return 'primary' }  
    },
  },
}
</script>

<style>
.btn-pushable-container {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.btn-pushable-container:hover:not([disabled]) {
  filter: brightness(110%);
}
.btn-pushable-container .btn-pushable-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.btn-pushable-container:hover:not([disabled]) .btn-pushable-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.btn-pushable-container:active:not([disabled]) .btn-pushable-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.btn-pushable-container .btn-pushable-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.btn-pushable-container .btn-pushable-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}
.btn-pushable-container:disabled {
  opacity: 0.65;
}
.btn-pushable-container.btn-pushable-primary .btn-pushable-edge {
  background: linear-gradient( to left, hsl(215deg 100% 16%) 0%, hsl(215deg 100% 32%) 8%, hsl(215deg 100% 32%) 92%, hsl(215deg 100% 16%) 100% );
}
.btn-pushable-container.btn-pushable-success .btn-pushable-edge {
  background: linear-gradient( to left, hsl(147deg 67% 16%) 0%, hsl(147deg 67% 32%) 8%, hsl(147deg 67% 32%) 92%, hsl(147deg 67% 16%) 100% );
}
.btn-pushable-container.btn-pushable-danger .btn-pushable-edge {
  background: linear-gradient( to left, hsl(340deg 100% 16%) 0%, hsl(340deg 100% 32%) 8%, hsl(340deg 100% 32%) 92%, hsl(340deg 100% 16%) 100% );
}
.btn-pushable-container.btn-pushable-warning .btn-pushable-edge {
  background: linear-gradient( to left, hsl(340deg 100% 16%) 0%, hsl(340deg 100% 32%) 8%, hsl(340deg 100% 32%) 92%, hsl(340deg 100% 16%) 100% );
}
.btn-pushable-container.btn-pushable-purple .btn-pushable-edge {
  background: linear-gradient( to left, hsl(275deg 100% 16%) 0%, hsl(275deg 100% 32%) 8%, hsl(275deg 100% 32%) 92%, hsl(275deg 100% 16%) 100% );
}
.btn-pushable-container.btn-pushable-primary .btn-pushable-front {
  background: hsl(220deg 100% 61%);
}
.btn-pushable-container.btn-pushable-success .btn-pushable-front {
  background: hsl(147deg 67% 47%);
}
.btn-pushable-container.btn-pushable-danger .btn-pushable-front {
  background: hsl(147deg 67% 47%);
}
.btn-pushable-container.btn-pushable-warning .btn-pushable-front {
  background: hsl(147deg 67% 47%);
}
.btn-pushable-container.btn-pushable-purple .btn-pushable-front {
  background: hsl(270deg 100% 71%);
}
.btn-pushable-container:hover:not([disabled]) .btn-pushable-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}
.btn-pushable-container:active:not([disabled]) .btn-pushable-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.btn-pushable-container:focus,
.btn-pushable-container:active {
  outline: none;
}

</style>
